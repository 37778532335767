<template>
    <v-row>
        <v-col cols="2">
            <settingDrawer />
        </v-col>
        <v-col cols="10">
            <team-setting-general v-if="activeMenu === '#definition'" :key="activeMenu" />
            <team-setting-members v-if="activeMenu === '#members'" :key="activeMenu" />
            <team-setting-views v-if="activeMenu === '#views'" :key="activeMenu" />
            <team-setting-kanban v-if="activeMenu === '#kanban'" :key="activeMenu" />
        </v-col>
    </v-row>
</template>

<script>
import settingDrawer from '@/components/drawers/drawer-team-settings';
import teamSettingGeneral from './setting/general';
import teamSettingMembers from './setting/members';
import teamSettingViews from './setting/views';
import teamSettingKanban from './setting/kanban';

export default {
    name: 'team-settings',
    data: () => ({}),
    components: {
        settingDrawer,
        teamSettingGeneral,
        teamSettingMembers,
        teamSettingViews,
        teamSettingKanban,
    },
    created() {
        if (!this.$route.hash) {
            this.$router.push({ name: this.$route.name, hash: '#definition' });
        }
    },
    computed: {
        activeMenu() {
            return this.$route.hash;
        },
    },
    methods: {},
};
</script>
