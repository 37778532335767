<template>
    <v-card v-if="team">
        <v-card-text>
            <v-data-table :headers="headers" :items="team.kanban" :items-per-page="20" sort-by="order">
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="800px" persistent>
                            <template v-slot:activator="{ on }">
                                <v-btn rounded color="primary" dark v-on="on">
                                    {{ $t('buttons.createTeamKanban') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <ValidationObserver ref="observer" v-slot="{ invalid }">
                                    <v-card-title>
                                        <span class="headline blue--text text--darken-1">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <ValidationProvider
                                            :name="$t('teams.kanban.order')"
                                            :rules="'decimal'"
                                            v-slot="{ errors }"
                                        >
                                            <v-text-field
                                                :label="$t('teams.kanban.order')"
                                                v-model="editKanban.order"
                                                :error-messages="errors[0]"
                                            ></v-text-field>
                                        </ValidationProvider>

                                        <v-text-field
                                            v-model="editKanban.label"
                                            :counter="20"
                                            :label="$t('teams.kanban.label')"
                                            required
                                        ></v-text-field>

                                        <selector-status v-model="editKanban.status" type="name" />

                                        <v-switch
                                            v-model="editKanban.close"
                                            :label="$t('teams.kanban.close')"
                                        ></v-switch>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="red darken-1" text @click="cancelEdit">
                                            {{ $t('buttons.cancel') }}
                                        </v-btn>
                                        <v-btn color="blue darken-1" text @click="save" :disabled="invalid">
                                            {{ $t('buttons.save') }}
                                        </v-btn>
                                    </v-card-actions>
                                </ValidationObserver>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <label-status :value="item.status" type="name" />
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import labelStatus from '@/components/labels/status';
import selectorStatus from '@/components/selectors/multiple/status';

export default {
    name: 'team-setting-kanban',
    data: () => ({
        dialog: false,
        editKanban: {
            id: undefined,
            order: 0,
            label: undefined,
            status: [],
            close: false,
        },
    }),
    created() {},
    components: { labelStatus, selectorStatus },
    watch: {},
    computed: {
        ...mapGetters(['team']),
        headers() {
            return [
                {
                    text: this.$t('teams.kanban.order'),
                    sortable: true,
                    value: 'order',
                },
                {
                    text: this.$t('teams.kanban.label'),
                    sortable: true,
                    value: 'label',
                },
                {
                    text: this.$t('teams.kanban.status'),
                    sortable: true,
                    value: 'status',
                },

                {
                    text: this.$t('teams.members.actions'),
                    sortable: false,
                    value: 'actions',
                    width: 50,
                },
            ];
        },
        formTitle() {
            return this.editKanban.id ? this.$t('title.editKanban') : this.$t('title.newKanban');
        },
    },
    methods: {
        ...mapActions(['addTeamKanban', 'updateTeamKanban', 'deleteTeamKanban']),
        cancelEdit() {
            this.dialog = false;
            this.editKanban = {
                id: undefined,
                order: 0,
                label: undefined,
                status: [],
                close: false,
            };
        },
        editItem(item) {
            this.dialog = true;
            this.editKanban = Object.assign({}, item);
        },
        deleteItem(item) {
            const kanbanToUpdate = {
                team: this.team.id,
                id: item.id,
            };

            this.deleteTeamKanban(kanbanToUpdate);
        },
        save() {
            const kanbanToUpdate = {
                team: this.team.id,
                id: this.editKanban.id,
                label: this.editKanban.label,
                status: this.editKanban.status,
                order: this.editKanban.order,
                close: this.editKanban.close,
            };

            if (kanbanToUpdate.id) {
                this.updateTeamKanban(kanbanToUpdate);
            } else {
                this.addTeamKanban(kanbanToUpdate);
            }
            this.cancelEdit();
        },
    },
};
</script>

<style></style>
