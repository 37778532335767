<template>
    <v-card>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="team.views"
                :items-per-page="20"
                sort-by="name"
                v-on:click:row="editItem"
                class="table-selector"
            >
                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-spacer></v-spacer>
                        <v-btn rounded color="primary" dark @click="createItem()">
                            {{ $t('views.actions.add') }}
                        </v-btn>
                    </v-toolbar>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'team-setting-views',
    data: () => ({}),
    created() {},
    components: {},
    watch: {},
    computed: {
        ...mapGetters(['team']),
        headers() {
            return [
                {
                    text: this.$t('views.attributes.group'),
                    sortable: true,
                    value: 'group',
                },
                {
                    text: this.$t('views.attributes.name'),
                    sortable: true,
                    value: 'name',
                },
                {
                    text: this.$t('views.attributes.label'),
                    sortable: false,
                    value: 'label',
                },
            ];
        },
    },
    methods: {
        createItem() {
            this.$router.push({ name: 'team-view-create' });
        },
        editItem(item) {
            this.$router.push({ name: 'team-view-edit', params: { view_id: item.id } });
        },
    },
};
</script>

<style scoped>
.table-selector {
    cursor: pointer;
}
</style>
