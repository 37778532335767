<template>
    <v-chip-group>
        <v-chip v-for="s in statusToDisplay" small :dark="dark" :color="color ? color : s.color" :key="s.value">
            {{ s.label }}
        </v-chip>
    </v-chip-group>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';
export default {
    name: 'label-status',
    data: () => ({}),
    created() {},
    mounted() {},
    props: {
        value: {},
        color: {
            default: null,
        },
        dark: {
            default: true,
        },
        type: {
            default: 'id',
        },
    },
    watch: {},
    computed: {
        ...mapGetters(['status']),
        statusToDisplay() {
            if (this.value) {
                return OrderBy(
                    this.status.filter((s) => {
                        return this.type === 'id' ? this.value.includes(s.id) : this.value.includes(s.name);
                    }),
                    ['value'],
                    ['asc'],
                );
            }
            return [];
        },
    },
    methods: {
        statusColor(role) {
            if (this.color) {
                return this.color;
            }
            if (role === 'productOwner') {
                return 'orange darken-2';
            }
            if (role === 'productManager') {
                return 'indigo darken-2';
            }

            return 'light-blue darken-2';
        },
    },
};
</script>
