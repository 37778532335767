var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.team.members,"items-per-page":20,"sort-by":"user"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"rounded":"","color":"primary","dark":""}},on),[_vm._v(" "+_vm._s(_vm.$t('buttons.createMember'))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline blue--text text--darken-1"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('selector-user',{attrs:{"label":_vm.$t('teams.members.user')},model:{value:(_vm.editMember.user),callback:function ($$v) {_vm.$set(_vm.editMember, "user", $$v)},expression:"editMember.user"}}),_c('selector-roles',{attrs:{"label":_vm.$t('teams.members.roles')},model:{value:(_vm.editMember.roles),callback:function ($$v) {_vm.$set(_vm.editMember, "roles", $$v)},expression:"editMember.roles"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.cancelEdit}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('labelRoles',{attrs:{"value":item.roles}})]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('label-user',{attrs:{"value":item.user,"avatar":true}})]}},{key:"item.joinedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.joinedAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }