<template>
    <app-drawer
        :menus="menus"
        :allow-mini="false"
        :clipped="false"
        :dark="false"
        :floating="true"
        color="transparent"
        :app="false"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import appDrawer from './drawer';

export default {
    name: 'drawer-team-settings',
    data: () => ({
        mini: true,
    }),
    components: {
        appDrawer,
    },
    computed: {
        ...mapGetters(['profile']),
        allMenus() {
            return [
                {
                    label: 'teams.settings.definition',
                    icon: 'mdi-text-box',
                    name: 'definition',
                    group: 'teams.settings.general',
                    position: 'top',
                },
                {
                    label: 'teams.settings.members',
                    icon: 'mdi-account-group',
                    name: 'members',
                    group: 'teams.settings.attributes',
                    position: 'top',
                },
                {
                    label: 'teams.settings.views',
                    icon: 'mdi-format-list-bulleted',
                    name: 'views',
                    group: 'teams.settings.attributes',
                    position: 'top',
                },
                {
                    label: 'teams.settings.kanban',
                    icon: 'mdi-view-column',
                    name: 'kanban',
                    group: 'teams.settings.attributes',
                    position: 'top',
                },
            ];
        },
        menus() {
            return this.allMenus;
        },
    },
};
</script>
