<template>
    <v-card>
        <v-card-text>
            <v-text-field
                v-model="name"
                :counter="20"
                :label="$t('teams.attributes.name')"
                required
                outlined
            ></v-text-field>
            <v-text-field
                v-model="reference"
                :counter="10"
                :label="$t('teams.attributes.reference')"
                required
                outlined
            ></v-text-field>

            <selector-projects v-model="projects" type="name" />

            <v-btn color="success" class="mr-4" @click="submitForm">
                {{ $t('teams.actions.update') }}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import selectorProjects from '@/components/selectors/multiple/projects';

export default {
    name: 'team-setting-general',
    data: () => ({
        name: undefined,
        reference: undefined,
        projects: [],
    }),
    components: {
        selectorProjects,
    },
    created() {
        this.updateForm(this.team);
    },
    watch: {
        team: function (value) {
            this.updateForm(value);
        },
    },
    computed: {
        ...mapGetters(['team']),
    },
    methods: {
        ...mapActions(['updateTeam']),
        updateForm(t) {
            if (t) {
                this.name = t.name;
                this.reference = t.reference;
                this.projects = t.projects;
            }
        },
        submitForm() {
            const teamToUpdate = {
                id: this.team.id,
                name: this.name,
                reference: this.reference,
                projects: this.projects,
            };
            this.updateTeam(teamToUpdate);
        },
    },
};
</script>

<style></style>
