var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.team)?_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.team.kanban,"items-per-page":20,"sort-by":"order"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"rounded":"","color":"primary","dark":""}},on),[_vm._v(" "+_vm._s(_vm.$t('buttons.createTeamKanban'))+" ")])]}}],null,false,2418543474),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',[_c('span',{staticClass:"headline blue--text text--darken-1"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":_vm.$t('teams.kanban.order'),"rules":'decimal'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('teams.kanban.order'),"error-messages":errors[0]},model:{value:(_vm.editKanban.order),callback:function ($$v) {_vm.$set(_vm.editKanban, "order", $$v)},expression:"editKanban.order"}})]}}],null,true)}),_c('v-text-field',{attrs:{"counter":20,"label":_vm.$t('teams.kanban.label'),"required":""},model:{value:(_vm.editKanban.label),callback:function ($$v) {_vm.$set(_vm.editKanban, "label", $$v)},expression:"editKanban.label"}}),_c('selector-status',{attrs:{"type":"name"},model:{value:(_vm.editKanban.status),callback:function ($$v) {_vm.$set(_vm.editKanban, "status", $$v)},expression:"editKanban.status"}}),_c('v-switch',{attrs:{"label":_vm.$t('teams.kanban.close')},model:{value:(_vm.editKanban.close),callback:function ($$v) {_vm.$set(_vm.editKanban, "close", $$v)},expression:"editKanban.close"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.cancelEdit}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)]}}],null,false,565147793)})],1)],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('label-status',{attrs:{"value":item.status,"type":"name"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }